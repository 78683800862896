import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BusMessageService {

  private genericConfirm   = new Subject();

  seGenericConfirm(message) {
    this.genericConfirm.next(message);
  } 

  getGenericConfirm(): Observable<any> {
      return this.genericConfirm.asObservable();
  }
  
}
