import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { TemplateService } from '../services/template.service';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { NgbDropdownConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LogoutConfirmationComponent } from '../logout-confirmation/logout-confirmation.component';
import { Location } from '@angular/common';
import { CompanyService } from '../services/company.service';
import { NotificationService } from '../services/notification.service';
import { SidebarBusMessageService } from '../services/sidebar-bus-message.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  public config: PerfectScrollbarConfigInterface = {};
  hideSidebar:boolean       = false;
  currentUser               = null;
  GedIsCollapsed:boolean    = true;
  ComIsCollapsed:boolean    = true;
  OblIsCollapsed:boolean    = true;
  AdminIsCollapsed:boolean  = true;
  NotifIsCollapsed:boolean  = true;
  isValidated               = null;
  collab                    = null;
  curUrl                    = null;
  notifications             = null;
  isReady:boolean           = null;
  subscription: Subscription;
  subscription2: Subscription;

  constructor(
    private router: Router, 
    private location: Location, 
    private templateService: TemplateService,
    private userService: UserService,
    private modalService: NgbModal,
    private companyService: CompanyService,
    private notification: NotificationService,
    private sidebarMessage: SidebarBusMessageService,
    config: NgbDropdownConfig
  ){
    this.initSidebar();
    config.placement = 'top-left';
  }

  initSidebar(){
      if(this.location.path().includes('/obligation')){
        this.OblIsCollapsed = false;
      }else if(this.location.path().includes('/ged') || this.location.path().includes('/espace/validation')){
        this.GedIsCollapsed = false;
      }else if(this.location.path().includes('/commercial')){
        this.ComIsCollapsed = false;
      }else if(this.location.path().includes('/administration')){
        this.AdminIsCollapsed = false;
      }
  }

  ngOnDestroy(){
    if(this.subscription) this.subscription.unsubscribe();
    if(this.subscription2) this.subscription2.unsubscribe();
  }

  ngOnInit() {
    this.templateService.getHideSidebar().subscribe(
      (state) => {
        this.hideSidebar = state;
      }
    );

    this.userService.currentUser.subscribe(
      (userData) => {
        if(userData && Object.keys(userData).length){
            userData['permissionsCustomer'] =  userData['permissionsCustomer'].filter(comp => comp.company.isEnabled == true);    
            this.currentUser         = userData;
            if(this.currentUser.type == 'CUSTOMER'){
              this.getNotification('GED|BANK', this.currentUser.currentCompany.id);
            }else if(this.currentUser){
              this.getNotification();
            }

            this.isValidated = this.currentUser.isValidated ? 'true' : 'false';
            if(this.currentUser.profil == "COLLAB" || this.currentUser.profil == "EXPERT"){
                this.ComIsCollapsed = true;
                this.GedIsCollapsed = true;
            }

            if(this.currentUser.type == "CUSTOMER"){
              this.getCollab();
            }
        }
      }
    );


    this.subscription = this.sidebarMessage.getGenericConfirm()
    .subscribe(
      (data) => {
        if(data.entity != "NOTIFICATION") return false;
        switch (data.type) {
          case 'REFRESH':
              console.log("refresh");
              if(this.currentUser.type == 'CUSTOMER'){
                this.getNotification('GED|BANK', this.currentUser.currentCompany.id);
              }else{
                this.getNotification();
              }
              break;
          default:
            break;
        }
      }
    )
  }

  getCollab(){
    this.companyService.getSubResource(this.currentUser.currentCompany.id, 'collab', {})
    .subscribe(
      data => {
        this.collab = data ? data : null;
      }
    )
  }

  changeCompany(company){
    this.userService.changeCurrentCompany(company);
  }

  public onScrollEvent(event: any): void {
    console.log(event);
  }

  toggleSidebar(){
    this.templateService.setHideSidebar(!this.hideSidebar);
  }

  logOut(){
    this.modalService.open(LogoutConfirmationComponent);
  }

  truncate(value: string, limit = 25, completeWords = false, ellipsis = '...') {
    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(' ');
    }
    return value && value.length > limit ? value.substr(0, limit) + ellipsis : value;
  }


  getNotification(scope='', company=''){
    this.subscription2 = this.notification.getNotifCompany(scope, company)
    .subscribe(
      (data) => {
        if(data){
            this.notifications  = data;
            this.isReady        = true;
            this.sidebarMessage.seGenericConfirm({entity: 'NOTIFICATION', type: 'DISPATCH', option: data});
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }


  switchMenu(key){
    switch (key) {

      case 'OblIsCollapsed':
        this.OblIsCollapsed = !this.OblIsCollapsed;
        if(!this.OblIsCollapsed){
          this.GedIsCollapsed = true;
          this.ComIsCollapsed = true;
          this.AdminIsCollapsed = true;
        }
        break;

      case 'GedIsCollapsed':
        this.GedIsCollapsed = !this.GedIsCollapsed;
        if(!this.GedIsCollapsed){
          this.OblIsCollapsed = true;
          this.ComIsCollapsed = true;
          this.AdminIsCollapsed = true;
        }
        break;

      case 'ComIsCollapsed':
        this.ComIsCollapsed = !this.ComIsCollapsed;
        if(!this.ComIsCollapsed){
          this.GedIsCollapsed = true;
          this.OblIsCollapsed = true;
          this.AdminIsCollapsed = true;
        }
        break;

      case 'AdminIsCollapsed':
        this.AdminIsCollapsed = !this.AdminIsCollapsed;
        if(!this.AdminIsCollapsed){
          this.GedIsCollapsed = true;
          this.ComIsCollapsed = true;
          this.OblIsCollapsed = true;
        }
        break;

      case 'NotifIsCollapsed':
        this.NotifIsCollapsed = !this.NotifIsCollapsed;
        if(!this.NotifIsCollapsed){
          this.GedIsCollapsed = true;
          this.ComIsCollapsed = true;
          this.OblIsCollapsed = true;
          this.AdminIsCollapsed = true;
        }
        break;
    
      default:
        break;
    }
  }

}
