import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable ,  BehaviorSubject ,  ReplaySubject } from 'rxjs';

import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import { map ,  distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';

export interface User {
  token: string;
  // refresh_token: string,
  // id: string;
  // image: string;
  // email: string;
  // gender: string;
  givenName: string;
  // familyName: string;
  // birthDate: Date;
  // isActive: boolean;
  // jobTitle: string;
}

@Injectable()
export class UserService {
  
  private currentUserSubject = new BehaviorSubject({});
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  private roleSubject = new BehaviorSubject([]);
  public roleUser = this.roleSubject.asObservable().pipe(distinctUntilChanged());

  

  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private jwtService: JwtService,
    private router: Router
  ) {
    
  }
  
  populate(first:boolean=false){
    if (this.jwtService.getToken()) {
      this.apiService.get('/users/me')
      .subscribe(
        data => this.setAuth(data, first),
        err => this.purgeAuth()
      );
    } else {
      this.purgeAuth();
    }
  }

  setAuth(user, first:boolean=false) {
    this.jwtService.saveUser(user);
    if(user.type == "CUSTOMER") user = this.initCurrentCompany(user.permissionsCustomer);
    this.currentUserSubject.next(user);
    this.roleSubject.next(user.roles);
    this.isAuthenticatedSubject.next(true);
    const refreshBudget = this.jwtService.getResfreshBudget();
    if(first || refreshBudget.length) this.goToEspace();
  }



  initCurrentCompany(permissions){
    let currentCompany = permissions[0].company;
    let companies =  permissions.filter(comp => comp.company.isEnabled == true);
    if(companies.length){
      currentCompany = permissions[0].company;
    }
    
    let user = null;
    let currentComp = this.jwtService.getCurrentCompany();
    if(currentComp == null){
      user = this.jwtService.saveCurrentCompany(currentCompany);
    }else{
      let isCompanyIn = false;
      for (let index = 0; index < permissions.length; index++) {
        if(permissions[index].company.id == currentComp.id){
          isCompanyIn = true;
          currentComp = permissions[index].company;
        }
      }
      if(isCompanyIn == false) user =  this.jwtService.saveCurrentCompany(currentCompany);
      else user = this.jwtService.saveCurrentCompany(currentComp);
    }
    return user;
  }


  changeCurrentCompany(company){
    let user =  this.jwtService.saveCurrentCompany(company);
    this.currentUserSubject.next(user);
    return user;
  }

  goToEspace(data?:any){
    let user = data ? data : this.jwtService.getuser();
    let redirectUri = this.jwtService.getRedirectUri();
    if(user.isValidated != true && user.type == "CUSTOMER"){
      this.router.navigateByUrl('/espace/validation')
    }else if(redirectUri != null){
      this.router.navigateByUrl(redirectUri);
    }else if(user.type == "CUSTOMER"){
      const refreshBudget = this.jwtService.getResfreshBudget();
      if(refreshBudget.length){
        this.router.navigateByUrl('/espace/dossiers/bank');
      }else{
        this.router.navigateByUrl('espace/dossiers/detailcompany');
      }
    }else if(user.profil == "COMMERCIAL"){
      this.router.navigateByUrl('/espace/commercial/base-lead')
    }else if(["EXPERT", "COLLAB", "CM", "SECRETARY"].includes(user.profil)){
      const refreshBudget = this.jwtService.getResfreshBudget();
      if(refreshBudget.length){
        this.router.navigateByUrl('/espace/dossiers/banque/'+refreshBudget.pop());
      }else{
        this.router.navigateByUrl('/espace/obligation/dashboard')
      }
    }
  }


  saveToken(user) {
    this.jwtService.saveToken(user.token);
    this.populate(true);
  }

  purgeAuth() {
    // Remove JWT from localstorage
    this.jwtService.destroyToken();
    // Set current user to an empty object
    this.currentUserSubject.next({});
    this.roleSubject.next([]);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
    
  }

  attemptAuth(credentials): Observable<any> {
    return this.apiService.post('/pub/login', credentials)
      .pipe(map(
      data => {
        this.saveToken(data.body);
        return data;
      }
    ));
  }


  forgetPassword(formValue): Observable<any> {
    return this.apiService.post('/pub/forgot-password-request', formValue)
      .pipe(map(
      data => {
        return data;
      }
    ));
  }

  resetPassword(formValue): Observable<any> {
    return this.apiService.post('/pub/reset-password-confirmation', formValue)
      .pipe(map(
      data => {
        return data;
      }
    ));
  }

  getCurrentUser() {
    return this.currentUserSubject.value;
  }

  getRole() {
    return this.roleSubject.value;
  }

  // Update the user on the server (email, pass, etc)
  update(user): Observable<any> {
    return this.apiService
    .put('/user', { user })
    .pipe(map(data => {
      // Update the currentUser observable
      this.currentUserSubject.next(data.user);
      return data.user;
    }));
  }


  add(payload): Observable<any> {
    return this.apiService
    .post(
      '/users',
      payload
    ).pipe(map(data => data.body));
  }

  updateOne(id, values): Observable<any> {
    return this.apiService
    .put('/users/'+id, values)
    .pipe(map(data => data));
  }

  delete(id): Observable<any> {
    return this.apiService
    .delete('/users/'+id)
    .pipe(map(data => data));
  }


  get(query): Observable<any> {
    return this.apiService.get('/users', query)
      .pipe(map(data => data));
  }

  getOne(id): Observable<any> {
    return this.apiService.get('/users/'+id)
      .pipe(map(data => data));
  }

  getCompaniesCollab(id, query): Observable<any> {
    return this.apiService.get('/users/'+id+'/companies', query)
      .pipe(map(data => data));
  }

  getStatsTeam(id, query): Observable<any> {
    return this.apiService.get('/companies/stats-team', query)
      .pipe(map(data => data));
  }

  userPost(id, path, payload): Observable<any> {
    return this.apiService
    .post(
      '/users/'+id+'/'+path,
      payload
    ).pipe(map(data => data.body));
  }

}
