import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingBarService } from '@ngx-loading-bar/core';

import { JwtService } from '../services';

@Injectable({
    providedIn: 'root'
})
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private jwtService: JwtService, private loadingBar: LoadingBarService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig = {
      'Accept': 'application/ld+json'
    };

    if(!((req.url.includes("/configurations") || req.url.includes("/filegenerics") || req.url.includes("/companies/import") || req.url.includes("/timetables/import")) && req.method == "POST")){
      headersConfig['Content-Type'] = 'application/json';
    }

    const token = this.jwtService.getToken();

    if (token) {
      headersConfig['Authorization'] = `Bearer ${token}`;
    }

    if(req.params.has('format') && ['ofx', 'csv', 'qif', 'csvcomplex'].includes(req.params.get('format'))){
      headersConfig['Accept'] = 'text/csv';
    }

    
    
    let request = req.clone({ setHeaders: headersConfig });
    const r = next.handle(request);

    

    let started = false;
    const responseSubscribe = r.subscribe.bind(r);
    r.subscribe = (...args) => {
      this.loadingBar.start();
      started = true;
      return responseSubscribe(...args);
    };

    return r.pipe(
      finalize(() => started && this.loadingBar.complete()),
    );
  }
}