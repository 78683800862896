// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


// export const environment = {
//   production: true,
//   api_url: 'https://wixapi.applize.fr/api',
//   media_url: 'https://wixapi.applize.fr'
// };


/*
export const environment = {
  production: false,
  api_url: 'https://api.myvesper.fr/api',
  media_url: 'https://api.myvesper.fr'
};


export const environment = {
  production: false,
  api_url: 'http://localhost:8080/api',
  media_url: 'http://localhost:8080',
  yousignUi: 'dba897aa-7413-42a9-9f61-d5ae4c042337'
};
*/

export const environment = {
  production: false,
  api_url: 'https://apidemo.myvesper.fr/api',
  media_url: 'https://apidemo.myvesper.fr',
  yousignUi: '6dd26d8a-9928-4618-8ea6-54d7e2641f7'
};