import { Injectable } from '@angular/core';
import { map ,  distinctUntilChanged } from 'rxjs/operators';
import { Observable ,  BehaviorSubject ,  ReplaySubject } from 'rxjs';
import { ApiService } from './api.service';
import { JwtService } from './jwt.service';

@Injectable({
  providedIn: 'root'
})
export class BootstrapService {
  private companySubject = new BehaviorSubject({});
  public company = this.companySubject.asObservable().pipe(distinctUntilChanged());

  constructor(private apiService: ApiService,
              private jwtService: JwtService) { }


  getCompany(code) {
    this.apiService.get('/pub/companies/'+code)
    .subscribe(
      data => this.setCompany(data),
      err => console.log('Cannot get company')
    );
  }


  setCompany(company) {
    this.jwtService.saveCompany(company);
    this.companySubject.next(company);
  }
}
