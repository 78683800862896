import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private apiService: ApiService) { }


  addCompanyUser(payload): Observable<any> {
    return this.apiService
    .post(
      '/companies/company-user',
      payload
    ).pipe(map(data => data.body));
  }


  add(payload): Observable<any> {
    return this.apiService
    .post(
      '/companies',
      payload
    ).pipe(map(data => data.body));
  }

  addCSV(payload): Observable<any> {
    return this.apiService
    .postWithFile(
      `/companies/import`,
      payload
    ).pipe(map(data => data.body));
  }

  getLegal(query, path): Observable<any> {
    return this.apiService.get('/companies'+path, query)
      .pipe(map(data => data));
  }

  send(payload, path): Observable<any> {
    return this.apiService
    .post(
      '/companies'+path,
      payload
    ).pipe(map(data => data.body));
  }

  getOne(id): Observable<any> {
    return this.apiService.get('/companies/' + id)
      .pipe(map(data => data));
  }

  getOneDetail(id): Observable<any> {
    return this.apiService.get('/companies-details/' + id)
      .pipe(map(data => data));
  }

  getCompanyPermission(id, query): Observable<any> {
    return this.apiService.get('/companies-permission/' + id, query)
      .pipe(map(data => data));
  }

  getSubResource(id, resource, query): Observable<any> {
    return this.apiService.get('/companies/'+id+'/'+resource, query)
      .pipe(map(data => data));
  }

  getAssign(id, query): Observable<any> {
    return this.apiService.get('/companies/' + id, query)
      .pipe(map(data => data));
  }

  get(query, path:string=""): Observable<any> {
    return this.apiService.get('/companies'+path, query)
      .pipe(map(data => data));
  }

  getFolder(query, path:string=""): Observable<any> {
    return this.apiService.get('/companies'+path, query)
      .pipe(map(data => data));
  }

  update(id, values): Observable<any> {
    return this.apiService
    .put('/companies/'+id, values)
    .pipe(map(data => data));
  }

  delete(id): Observable<any> {
    return this.apiService
    .delete('/companies/'+id)
    .pipe(map(data => data));
  }

  exportCompany(query): Observable<any> {
    return this.apiService.post('/companies/export', query)
      .pipe(map(data => data.body));
  }

  getProabonoLinks(query): Observable<any> {
    return this.apiService.get('/proabono/links', query)
      .pipe(map(data => data));
  }

  enableProabono(id): Observable<any> {
    return this.apiService.post('/proabono/create-client/'+id, {})
      .pipe(map(data => data.body));
  }

  desableProabono(id): Observable<any> {
    return this.apiService.delete('/proabono/delete-client/'+id)
      .pipe(map(data => data));
  }

  getProabonoSubscription(id): Observable<any> {
    return this.apiService.get('/proabono/subscriptions/'+id)
      .pipe(map(data => data));
  }

  postProabonoSubscription(id, query): Observable<any> {
    return this.apiService.post('/proabono/subscriptions/'+id, query)
      .pipe(map(data => data.body));
  }
  
  stateProabonoSubscription(id, type, query={}): Observable<any> {
    return this.apiService.post('/proabono/state-subscription/'+id+'?type='+type, query)
      .pipe(map(data => data.body)); 
  }

  getProabonoBalance(id): Observable<any> {
    return this.apiService.get('/proabono/balance/'+id)
      .pipe(map(data => data));
  }

  postLineBalance(id, body): Observable<any> {
    return this.apiService.post('/proabono/balance/'+id, body)
      .pipe(map(data => data.body)); 
  }

  syncOffer(query): Observable<any> {
    return this.apiService.get('/proabono/sync', query)
      .pipe(map(data => data));
  }

  postFeature(id, query): Observable<any> {
    return this.apiService.post('/proabono/features/'+id, query)
      .pipe(map(data => data.body));
  }
  
  deleteBalance(id, balanceId): Observable<any> {
    return this.apiService.delete('/proabono/balances/'+id+'?balance='+balanceId)
      .pipe(map(data => data.body));
  }

  stateProabonoInvoice(id, type, invoice, query={}): Observable<any> {
    return this.apiService.post('/proabono/state-invoice/'+id+'?type='+type+'&invoice='+invoice, query)
      .pipe(map(data => data.body)); 
  }

  getInvoice(id): Observable<any> {
    return this.apiService.get('/proabono/stats-invoice/'+id)
      .pipe(map(data => data));
  }

  getProabonoLogs(id, query): Observable<any> {
    return this.apiService.get('/proabono/logs/'+id, query)
      .pipe(map(data => data));
  }

  getStatsFolders(query): Observable<any> {
    return this.apiService.get('/companies/stats-folders', query)
      .pipe(map(data => data));
  }

  enableCompany(id): Observable<any> {
    return this.apiService
    .put('/companies/activation/'+id, {})
    .pipe(map(data => data));
  }


  getCompanyTimes(query): Observable<any> {
    return this.apiService.get('/companies-times/', query)
      .pipe(map(data => data));
  }

  getItems(query, path:string=""): Observable<any> {
    return this.apiService.get('/companies'+path, query)
      .pipe(map(data => data['hydra:member']));
  }
  
}
