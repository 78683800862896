import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserService } from './core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';
import { TemplateService } from './core/services/template.service';
import { BootstrapService } from './core/services/bootstrap.service';
import { CompanyService } from './core/services/company.service';
import { Subscription, fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BusMessageService } from './modules/customer/services/bus-message.service';
import { SidebarBusMessageService } from './core/services/sidebar-bus-message.service';
import { isArray } from 'util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit{
  title = 'FRONT';
  espaceclient:boolean = false;
  hideSidebar:boolean = false;
  authScreen:boolean = false;
  currentUser = null;
  collab = null;
  today = new Date();
  keyword:string = "";
  isSearching:boolean = false;
  router = null;
  subscription: Subscription;
  subscription2: Subscription;
  path = null;
  isCustomer = null;
  notifications = null;
  isValidated   = false;
  isProabono:boolean = false;
  subscribe = false;
  @ViewChild('searchFolderInput', {static: false}) searchFolderInput: ElementRef;

  constructor(
    location: Location, 
    router: Router, 
    private userService: UserService,
    private bootstrapService: BootstrapService,
    private templateService: TemplateService ,
    private companyService: CompanyService,
    private route: ActivatedRoute,
    public busMessage: BusMessageService,
    private sidebarMessage: SidebarBusMessageService
    ) {
    this.router = router;
    router.events.subscribe((val) => {
      this.path = location.path();
      if(location.path() == "/connexion" || location.path() == "/mot-de-passe-oublie" || location.path().includes('reinitilisation-mot-de-passe')){
        this.authScreen = true;
      }else{
        this.authScreen = false;
      }

      if(location.path().includes('/espace')){
        this.espaceclient = true;
      }else{
        this.espaceclient = false;
      }

      if(this.currentUser && this.currentUser.type != "CUSTOMER"){
        this.collab = null;
      }

    });
  }

  ngOnInit() {
    this.templateService.getHideSidebar().subscribe(
      (state) => {
        console.log(state);
        this.hideSidebar = state;
      }
    );

    this.bootstrapService.getCompany('MBSN');
    let redirectUri = window.localStorage['redirectUri'] ? true : false; 
    this.userService.populate(redirectUri);

    this.userService.currentUser.subscribe(
      (userData) => {
        if(userData && Object.keys(userData).length > 0){

          if(userData['company']['statusModule'] && 
            isArray(userData['company']['statusModule']) && 
            userData['company']['statusModule'].includes("PROABONO")
          ){
            this.isProabono = true;
          }

          this.currentUser = userData;
          this.isValidated = this.currentUser.isValidated ? true : false;
          console.log("isValidated", this.isValidated);
          if(this.currentUser.type == "CUSTOMER"){
            this.isCustomer  = true;
            this.getCollab();
            this.getCompany(this.currentUser.currentCompany.id);
          }else{
            this.isCustomer = false;
          }
        }
      }
    );
    
    this.subscription = this.busMessage.getGenericConfirm()
    .subscribe(
      (data) => {
        if(data.entity != "BASECLIENT") return false;
        switch (data.type) {
          case 'REMOVE':
                this.searchFolderInput.nativeElement.value = null;
                this.keyword = '';
              break;
          case 'ADD':
            this.searchFolderInput.nativeElement.value = data.text;
            this.keyword = '';
          break;
          default:
            break;
        }
      }
    )

    this.subscription2 = this.sidebarMessage.getGenericConfirm()
    .subscribe(
      (data) => {
        if(data.entity != "NOTIFICATION") return false;
        switch (data.type) {
          case 'DISPATCH':
              this.notifications = data.option;
              break;
          default:
            break;
        }
      }
    )

  }


  

  getCompany(idCompany) {
    this.companyService.getOneDetail(idCompany)
    .subscribe(
      (data) => {
        if(data){
          if(data.customFields && 
            data.customFields.packageSubscriptionCabinet != undefined && 
            data.customFields.packageSubscriptionCabinet != "" && 
            data.customFields.PROABONO_CLIENT_MANDAT != undefined){
              this.subscribe = true;
          }
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }


  getCollab(){
    this.companyService.getSubResource(this.currentUser.currentCompany.id, 'collab', {})
    .subscribe(
      data => {
        this.collab = data ? data : null;
      }
    )
  }

  toggleSidebar(){
    this.templateService.setHideSidebar(!this.hideSidebar);
  }

  ngAfterViewInit(){
    this.searchFolder();
  }

  searchFolder(){
    fromEvent(this.searchFolderInput.nativeElement, 'keyup').pipe(
      map((event: any) => {
        this.keyword = event.target.value;
        // this.isSearching = true;
        return event.target.value;
      })
      ,debounceTime(500)          
      ,distinctUntilChanged()
      ).subscribe((text: string) => {
        if(this.path.includes('dossiers/list')){
          this.busMessage.seGenericConfirm({entity: 'LISTFOLDERS', type: 'KEYWORD', text: text});
        }else{
          this.router.navigate(['espace/dossiers/list'], { queryParams: {q: text}});
        }
      });
  }

  launchSearch(){
    this.router.navigate(['espace/dossiers/list'], { queryParams: {q:  this.keyword}});
  }

}