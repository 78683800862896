import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private apiService: ApiService) { }

  getNotifFile(query): Observable<any> {
    return this.apiService.get('/filegenerics/notification', query)
      .pipe(map(data => data));
  }


  getNotifConf(query): Observable<any> {
    return this.apiService.get('/timetable/notification', query)
      .pipe(map(data => data));
  }

  getNotifBank(query): Observable<any> {
    return this.apiService.get('/bank/notification', query)
      .pipe(map(data => data));
  }

  get(query): Observable<any> {
    return this.apiService.get('/companies/notifications', query)
      .pipe(map(data => data));
  }

  getNotifCompany(scope, company): Observable<any> {
    return this.apiService.get('/companies/notifications?scope='+scope+'&company='+company)
      .pipe(map(data => data));
  }
}
