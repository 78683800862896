import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout-confirmation',
  templateUrl: './logout-confirmation.component.html',
  styleUrls: ['./logout-confirmation.component.scss']
})
export class LogoutConfirmationComponent {

  message:string = "";

  constructor(public modal: NgbActiveModal, private userService: UserService, private router: Router) {}

  confirm(value:boolean){
    if(value == true){
      this.userService.purgeAuth();
      this.router.navigateByUrl('/')
      setTimeout(() => {
        document.location.reload();
      }, 300);
      this.modal.close();
    }else{
      this.modal.close();
    }
  }
}
