import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UserService } from './services/user.service';
import { TemplateService } from './services/template.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ShowAuthedDirective } from './directives/show-authed.directive';
import { JwtService, ApiService } from './services';
import {NgbTooltipModule, NgbModalModule, NgbDropdownModule, NgbCollapseModule} from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { SidebarComponent } from './sidebar/sidebar.component';
import { PerfectScrollbarModule, PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { NoAuthGuard } from './services/no-auth-guard.service';
import { AuthGuard } from './services/auth-guard.service';
import { BootstrapService } from './services/bootstrap.service';
import { HasRoleDirective } from './directives/has-role.directive';
import { LogoutConfirmationComponent } from './logout-confirmation/logout-confirmation.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true
};

@NgModule({
  declarations: [ShowAuthedDirective, HeaderComponent, FooterComponent, SidebarComponent, HasRoleDirective, LogoutConfirmationComponent],
  imports: [
    NgbDropdownModule,
    NgbCollapseModule,
    LoadingBarRouterModule,
    CommonModule,
    RouterModule,
    NgbTooltipModule,
    BrowserAnimationsModule,
    PerfectScrollbarModule,
    NgbModalModule
  ],
  entryComponents:[LogoutConfirmationComponent],
  exports:[ShowAuthedDirective, HeaderComponent, FooterComponent, SidebarComponent, HasRoleDirective],
  providers: [
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    UserService,
    JwtService,
    ApiService,
    TemplateService,
    NoAuthGuard,
    AuthGuard,
    BootstrapService
  ]
})
export class CoreModule { }
