import { Injectable } from '@angular/core';

@Injectable()
export class JwtService {

  getToken(): String {
    return window.localStorage['jwtToken'];
  }

  saveToken(token: String) {
    window.localStorage['jwtToken'] = token;
  }

  saveUser(user){
    window.localStorage['user'] = JSON.stringify(user);
  }

  saveCurrentCompany(company){
    window.localStorage['currentCompany'] = JSON.stringify(company);
    let user = this.getuser();
    user.currentCompany = company;
    this.saveUser(user);
    return user;
  }

  getCurrentCompany(){
    return JSON.parse(window.localStorage['currentCompany'] ? window.localStorage['currentCompany'] : null);
  }

  getuser() {
    return JSON.parse(window.localStorage['user'] ? window.localStorage['user'] : null);
  }

  saveCompany(company){
    window.localStorage['company'] = JSON.stringify(company);
  }

  getCompany() {
    return JSON.parse(window.localStorage['company'] ? window.localStorage['company'] : null);
  }


  destroyToken() {
    window.localStorage.removeItem('jwtToken');
    window.localStorage.removeItem('user');
    // window.localStorage.removeItem('currentCompany');
  }

  saveResfreshBudget(company){
    window.localStorage['budgetCompany'] = JSON.stringify(company);
  }

  getResfreshBudget() {
    return window.localStorage['budgetCompany'] ? JSON.parse( window.localStorage['budgetCompany']) : [];
  }

  saveRedirectUri(uri){
    window.localStorage['redirectUri'] = uri;
  }

  getRedirectUri() {
    return window.localStorage['redirectUri'] ? window.localStorage['redirectUri'] : null;
  }

}
