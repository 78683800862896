import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';


const routes: Routes = [
  {path: "", loadChildren: "./modules/website/website.module#WebsiteModule"},
  {path: "", loadChildren: "./modules/ged/ged.module#GedModule"},
  {path: "", loadChildren: "./modules/commercial/commercial.module#CommercialModule"},
  {path: "", loadChildren: "./modules/obligation/obligation.module#ObligationModule"},
  {path: "", loadChildren: "./modules/administration/administration.module#AdministrationModule"},
  {path: "", loadChildren: "./modules/customer/customer.module#CustomerModule"},
  {path: "", loadChildren: "./modules/notification/notification.module#NotificationModule"}
];


const routerOptions: ExtraOptions = {
  useHash: false,
  // onSameUrlNavigation: 'reload',
  // scrollPositionRestoration: "enabled"
  // anchorScrolling: 'enabled',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
