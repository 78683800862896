import { Injectable } from '@angular/core';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { map ,  distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  private hideSidebar = new ReplaySubject<boolean>(1);

  constructor() { }

  setHideSidebar(state:boolean) {
      this.hideSidebar.next(state);
  }

  getHideSidebar(): Observable<any> {
      return this.hideSidebar.asObservable().pipe(distinctUntilChanged());
  }

}
