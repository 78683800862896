import { Component, OnInit, AfterViewInit, HostBinding, Input } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { UserService } from '../services/user.service';
import { fromEvent } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  share,
  throttleTime,
  debounceTime,
  tap
} from 'rxjs/operators';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { TemplateService } from '../services/template.service';

enum VisibilityState {
  Visible = 'visible',
  Hidden = 'hidden'
}

enum Direction {
  Up = 'Up',
  Down = 'Down'
}


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  /*
  animations: [
    trigger('toggle', [
      state(
        VisibilityState.Hidden,
        style({ opacity: 0, transform: 'translateY(-100%)' })
      ),
      state(
        VisibilityState.Visible,
        style({ opacity: 1, transform: 'translateY(0)' })
      ),
      transition('* => *', animate('100ms ease-in'))
    ])
  ]*/
})
export class HeaderComponent implements OnInit, AfterViewInit {
  public isVisible = true;
  private currentFragment = '';
  private isFragmentChange = false;
  @Input() espaceclient: boolean;
  // private isFragmentChangeBef = false;
  currentUser = null;
  scroll = false;
  hideSidebar:boolean = false;
  currentMenu = 'HOME';
  collab = null;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private templateService: TemplateService,
    private router: Router
  ) { }
  
  setClass(id){
    this.currentMenu = id;
  }

  toggleSidebar(){
    this.templateService.setHideSidebar(!this.hideSidebar);
  }

  goToEspace(){
    this.userService.goToEspace();
  }

  ngOnInit() {
    this.userService.currentUser.subscribe(
      (userData) => {
        this.currentUser = userData;
      }
    );

    this.templateService.getHideSidebar().subscribe(
      (state) => {
        this.hideSidebar = state;
      }
    );

    this.route.fragment
    .subscribe((fragment: string) => {
      this.isFragmentChange = (this.currentFragment != fragment && fragment != null && fragment != '') ? true : false;
      this.currentFragment = fragment;
      if(this.currentFragment == 'contactez-nous'){
        this.currentMenu = 'CONTACT';
      }

      

      if(this.isFragmentChange){
        setTimeout(() => {
          // this.isFragmentChange = this.isFragmentChangeBef;
          this.isVisible = false
        }, 1000);

        setTimeout(() => {
          this.isFragmentChange = true;
        }, 1500);
        
      }
      // console.log("IsFragmentChange : "+this.isFragmentChange);
    });

  }

  //@HostBinding('@toggle')
  get toggle(): VisibilityState {
    if(this.espaceclient) return;
    return this.isVisible ? VisibilityState.Visible : VisibilityState.Hidden;
  }

  ngAfterViewInit() {
    const scroll$ = fromEvent(window, 'scroll').pipe(
      throttleTime(10),
      map(() => window.pageYOffset),
      pairwise(),
      map(([y1, y2]): Direction => (y2 < y1 ? Direction.Up : Direction.Down)),
      distinctUntilChanged((prev, next) => {
        // if(prev != next)
        if(this.isFragmentChange) { 
          this.isFragmentChange = false; return false; }
        return (prev != next) ? false : true;
      }),
      share()
    );

    const goingUp$ = scroll$.pipe(
      filter(direction => direction === Direction.Up)
    );

    const goingDown$ = scroll$.pipe(
      filter(direction => direction === Direction.Down)
    );

    goingUp$.subscribe(() => { 
      this.isVisible = true;
    });
    goingDown$.subscribe(() => (this.isVisible = false));
  }

  logOut(){
    this.userService.purgeAuth();
    this.router.navigateByUrl('/')
  }

}
